import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { orange, white, gray } from 'src/styles/colors'

export const Section = styled.div`
  h1, h2 {
    font-family: "Sora";
  }

  .bread-crumb {
    span {
      color: ${gray['800']} !important;

      a {
        color: ${gray['800']};
        font-weight: 900;
      }
    }
  }

  .gatsby-image-wrapper {
    max-width: 456px;
    margin: 0 auto;
    margin-right: 0;
  }
`

export const Button = styled.button`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  background: ${orange.extra};
  color: ${white};
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  border: none;

  &:focus {
    outline: none;
  }

  @media(min-width: ${breakpoints.lg}) {
    max-width: 456px;
  }
`

export const FAQSection = styled.section`
  .search-input {
    background: ${white};
  }
`
