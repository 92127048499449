import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      bannerPoupanca: imageSharp(fluid: {originalName: { regex: "/banner-poupanca/" }}) {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
